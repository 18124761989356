import React from 'react';
import Link from "gatsby-link";
import {Col} from "react-bootstrap";

function Casestudycategory({data}) {
    return (
        <>

            <Col xs={12} md={9} lg={9} xl={9} xxl={9}>
                <div className="cateorgy_namee">
                    <h4>Case Studies</h4>
                    <div className="menu_items case-study-links">
                        {data?.map((value, i) => (
                            <li>
                                <Link to={`/profile/${value.slug}`}>{value.name}</Link>
                            </li>
                        ))}
                    </div>
                </div>
            </Col>

        </>
    );
}

export default Casestudycategory;